
.lightui1{
    box-shadow: 0 0 0 1px #f3f5f4;
    border-radius:2px;
    padding: 12px;
    background: #ffffff;
}

.lightui1-shimmer {
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    background: #d8d8d8;
    background-image: linear-gradient(to right, #d8d8d8 0%, #bdbdbd 20%, #d8d8d8 40%, #d8d8d8 100%);
    background-repeat: no-repeat;
    background-size: 800px 210px;
    height: 210px;
    position: relative
}

.lightui1-shimmer div {
    background: #ffffff;
    height: 8px;
    left: 0;
    position: absolute;
    right: 0
}


.lightui1b-shimmer {
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    background: #f0f0f0;
    background-image: linear-gradient(to right, #f0f0f0 0%, #d8d8d8 20%, #f0f0f0 40%, #f0f0f0 100%);
    background-repeat: no-repeat;
    background-size: 800px 210px;
    height: 104px;
    position: relative
}

div._2iwz {
    height: 13px;
    top: 72%;
}

div._2iw- {
    left: 85%;
    top: 78%;
}

div._2iw_ {
    height: 13px;
    top: 82%;
}

div._2ix0 {
    left: 75%;
    top: 88%;
}

div._2ix01 {
    top: 92%;
    height: 18px;
}

@-webkit-keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 0
    }
}

@-webkit-keyframes prideShimmer {
    from {
        background-position: top left
    }
    to {
        background-position: top right
    }
}
