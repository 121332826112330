.HeaderListProduct {
    top: 0;
    padding-left: 32px;
    padding-right: 32px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    position: sticky;
    background: #fff;
    z-index: 10;
    &.NavCatalog {
        z-index: 0;
        position: relative;
    }
    .HeaderTitle {
        font-size: 18px;
        font-weight: 600;
        left: 50%;
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
    }
    .RowHeaderListProduct {
        height: 80px;
    }

    a.BackButton {
        display: inline-block;
        align-items: center;
        color: #5c60c4;
        font-weight: 700;
        font-size: 14px;
        cursor: pointer;

        .Text {
            margin-left: 12px;
            vertical-align: middle;
        }
    }

    .SearchProductMockup {
        position: relative;
        min-width: 440px;
        margin-right: 11px;

        input {
            padding: 9px 24px 9px 40px;
            width: 440px;
            max-width: 100%;
            border: 1px solid #d3dcd7;
            border-radius: 4px;
            transition: all 0.3s;

            &::placeholder {
                font-size: 14px;
            }

            &:focus {
                border: 1px solid #007bff;
            }
        }

        .SearchIcon {
            position: absolute;
            left: 18px;
            top: 21px;
            font-size: 14px;
            transform: translateY(-55%);
        }

        @media (max-width: 767px) {
            min-width: 300px;
        }
    }
}

