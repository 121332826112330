.modal-upload-font {
    .PFBoxUploader {
        box-sizing: border-box;
        border-radius: 8px;
        text-align: center;
        border: 2px dashed #dee2e6;
        > label {
            cursor: pointer;
            padding: 100px 0;
            > img {
                width: 70px;
            }
            .Text {
                cursor: auto;
            }
        }
    }
}