.ListProductLineConfirm {
    .cursor-pointer:hover {
        cursor: pointer;
    }

    .HeaderListProductLine {
        display: flex;
        margin-bottom: 24px;
        padding: 32px 32px 0 32px;

        .Title {
            color: #2f3230;
            font-weight: 500;
            font-size: 22px;
            flex-grow: 1;
        }
        .AddMoreProduct {
            flex-grow: 1;
            text-align: right;
            color: #007bff;
            font-weight: bold;
            font-size: 14px;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: flex-end;
        }

        .AddMoreProduct {
            span:hover {
                cursor: pointer;
            }
        }
    }

    .ContentListProductLine {
        //padding: 0 24px;
        margin-right: 4px;
        max-height: calc(100vh - 220px);
        overflow-y: auto;
        border-bottom: 1px solid #D8DBDE;
    }
    .SubAddMoreProduct {
        display: none;
        margin-top: -10px;
        padding: 0 14px 32px 14px;
        label {
            width: 100%;
            cursor: pointer;
            text-align: center;
            padding-top: 15px;
            border: 1px dashed #dee2e6;
            border-radius: 0.25rem;
            background: #ffffff;
        }
    }
    .ContentListProductLine::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #d3dcd7;
        border-radius: 10px;
        background-color: #fafbfa;
    }

    .ContentListProductLine::-webkit-scrollbar {
        width: 8px;
        background-color: #fafbfa;
    }

    .ContentListProductLine::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px #d3dcd7;
        background-color: #d3dcd7;
    }
    .ItemProductLineError {
        border: 2px solid #dc3545 !important;
    }

    .ItemProductLineActivate {
        border: 2px solid #007bff !important;
    }

    .ItemProductLineActivate:hover {
        cursor: default !important;
    }

    .ItemProductLine:hover {
        cursor: pointer;
    }

    .cursorDefault:hover {
        cursor: default !important;
    }

    .ItemCatalog,
    .ItemProductLine {
        //background: #ffffff;
        //border: 1px solid #d3dcd7;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 12px;
        margin-bottom: 16px;
        position: relative;
        transition: all 0.3s;

        .TopRight {
            position: absolute;
            top: 2px;
            right: 10px;
        }

        .IconRemove {
            cursor: pointer;
            transition: all 0.3s;
            color: #6e7370;
            margin-left: 8px;

            &:hover {
                color: #dc3545;
            }
        }

        .HeaderItem {
            flex-grow: 1;
            display: flex;
            align-items: center;

            .Img {
                width: 72px;
                flex-shrink: 0;

                .ImageWrapper {
                    position: relative;
                    width: 100%;
                    flex-shrink: 0;
                    padding: 0 0 100% 0;
                    img {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        max-width: 100%;
                        max-height: 100%;
                        margin: auto;
                    }
                }
            }
            .Title {
                max-width: 208px;
                margin-left: 16px;
                display: flex;
                flex-wrap: wrap;
                color: #2f3230;
                font-weight: 500;
                font-size: 16px;
                align-content: center;
                margin-right: auto;
            }

            .ProductArtworkError {
                margin-left: 16px;
                color: #ffb800;
            }
        }

        .ContentItem {
            display: flex;
            flex-wrap: wrap;
            .Title {
                color: #2f3230;
                font-weight: bold;
                font-size: 14px;
                margin-top: 16px;
                margin-bottom: 12px;
                width: 100%;
            }
            .StyleColor {
                width: 100%;
                margin-bottom: 14px;
                li {
                    float: left;
                    padding-right: 24px;
                    font-size: 14px;
                    line-height: 20px;
                    color: #a9afab;
                }
                li:hover {
                    cursor: pointer;
                }

                .Activate {
                    color: #2f3230;
                }
            }

            .GroupStyleColor {
                width: 100%;
                li {
                    float: left;
                    .InputColor {
                        padding: 0;
                        height: initial;
                        width: initial;
                        margin-bottom: 0;
                        display: none;
                        cursor: pointer;
                    }
                    .ColorItem,
                    .CheckMark {
                        position: relative;
                        cursor: pointer;
                    }
                    .ColorItem,
                    .CheckMark {
                        -webkit-appearance: none;
                        border: 1px solid #d3dcd7;
                        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
                        padding: 10px;
                        display: inline-block;
                        position: relative;
                        vertical-align: middle;
                        cursor: pointer;
                        border-radius: 50%;
                        width: 28px;
                        height: 28px;
                        background-color: #15cd72;
                    }
                    .InputColor:checked + .CheckMark:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 4px;
                        left: 11px;
                        width: 6px;
                        height: 14px;
                        border: solid white;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                    }

                    .InputColor:checked + .CheckMark::before {
                        content: '';
                        background-color: rgba(0, 0, 0, 0.1);
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                }
                .AddAttributesColor {
                    border: 1px solid #d3dcd7;
                    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
                    display: inline-block;
                    position: relative;
                    vertical-align: middle;
                    cursor: pointer;
                    margin-right: 8px;
                    border-radius: 50%;
                    width: 28px;
                    height: 28px;
                    background-color: #ffffff;
                    text-align: center;
                    font-weight: bold;
                }
            }

            .GroupStyleColor,
            .GroupStyleAttribute {
                .d-flex {
                    gap: 10px;
                }
                li {
                    &.disabled {
                        opacity: 0.5;
                        cursor: not-allowed !important;
                        .ColorItem {
                            cursor: not-allowed !important;
                        }
                    }
                }
            }

            .GroupSize {
                width: 100%;
                .Title {
                    // font-weight: bold;
                    // font-size: 14px;
                    // color: #2F3230;
                    // margin-top: 16px;
                    // margin-bottom: 12px;
                }
                li {
                    float: left;
                    margin-right: 8px;
                    margin-bottom: 8px;
                    background: #fafbfa;
                    border: 1px solid #d3dcd7;
                    box-sizing: border-box;
                    border-radius: 4px;
                    font-weight: bold;
                    font-size: 14px;
                    padding: 6px 20px;
                }
                li:hover {
                    cursor: pointer;
                }

                .Activate {
                    background: #e3f2eb;
                    border: 1px solid #007bff;
                    box-sizing: border-box;
                    border-radius: 4px;
                    color: #007bff;
                }
            }

            .GroupProductPrice {
                display: flex;
                width: 100%;
                .FormFeedbackPrice {
                    min-width: 190px;
                    font-size: 12px;
                    padding: 0.03rem 0.2rem;
                }
                .SetProductPriceInput {
                    flex-grow: 1;

                    .InputPrice {
                        width: 120px;

                        &::placeholder {
                            color: #a9afab;
                        }

                        &:focus {
                            border: 1px solid #15cd72;
                        }
                    }
                }
                .SetProductPriceInputError {
                    .Title {
                        color: #dc3545 !important;
                    }
                    .InputPrice {
                        border-color: #dc3545;
                    }
                }
                .SetProductPriceTitle {
                    flex-grow: 1;
                    text-align: right;
                    .AdvancePricing {
                        color: #007bff;
                        font-weight: bold;
                        font-size: 14px;
                        margin-top: 16px;
                        margin-bottom: 12px;
                    }
                    .AdvancePricing {
                        span:hover {
                            cursor: pointer;
                        }
                    }
                    .ProfitSale {
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 38px;
                        color: #15cd72;
                    }
                }
            }
        }
        .SelectColor {
            .Title {
                font-weight: bold;
                font-size: 14px;
                margin-bottom: 12px;
                width: 100%;
                color: #2f3230;
            }
            ul li {
                float: left;
                padding-right: 24px;
                font-size: 14px;
                line-height: 20px;
                color: #a9afab;
            }
            ul {
                .Activate {
                    color: #2f3230;
                }
            }
            ul li:hover {
                cursor: pointer;
            }
            .CheckBoxColor {
                li {
                    float: left;
                    padding: 0;
                    .InputColor {
                        padding: 0;
                        height: initial;
                        width: initial;
                        margin-bottom: 0;
                        display: none;
                        cursor: pointer;
                    }
                    .CheckMark {
                        -webkit-appearance: none;
                        border: 1px solid #d3dcd7;
                        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
                        padding: 10px;
                        display: inline-block;
                        position: relative;
                        vertical-align: middle;
                        cursor: pointer;
                        margin-right: 8px;
                        border-radius: 50%;
                        width: 28px;
                        height: 28px;
                        background-color: #15cd72;
                    }

                    .InputColor:checked + .CheckMark:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 4px;
                        left: 11px;
                        width: 6px;
                        height: 14px;
                        border: solid white;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                    }

                    .InputColor:checked + .CheckMark::before {
                        content: '';
                        background-color: rgba(0, 0, 0, 0.1);
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                }
            }
        }

        &:not(.ItemProductLineActivate):hover {
            background-color: #f2f2f2;
        }
    }

    .ItemCatalog {
        padding: 0;
        margin-bottom: 0;
        .HeaderItem {
            padding: 12px;
            border-bottom: 1px solid #d8dbde;
        }
        .ContentItem {
            padding: 12px;
        }
        &.View {
            padding: 0;
            border: none;
            background-color: inherit;
        }
    }

    .ItemCatalog {
        cursor: auto;

        .ContentItem {
            .ProductVariants {
                .OtherAttributes {
                    ul {
                        gap: 10px;
                        li {
                            cursor: pointer;
                            text-align: center;
                            min-width: 45px;
                            padding: 6px 10px;
                            border-radius: 4px;
                            background-color: inherit;
                            border: 1px solid #d8dbde;
                            transition: 0.3s all;
                            &:hover {
                                opacity: 0.7;
                            }
                            &:active,
                            &.active {
                                color: #007bff;
                                background-color: #e6f6ff;
                                border-color: #007bff;
                            }
                        }
                    }
                    @media (max-width: 1600px) {
                        li {
                            min-width: 40px;
                            padding: 4px 8px;
                        }
                    }
                }
                .ColorAttribute {
                    ul {
                        li {
                            .ColorItem {
                                &.active {
                                    &::after {
                                        content: '';
                                        display: block;
                                        position: absolute;
                                        top: 4px;
                                        left: 11px;
                                        width: 6px;
                                        height: 14px;
                                        border: solid white;
                                        border-width: 0 2px 2px 0;
                                        transform: rotate(45deg);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &:not(.ItemProductLineActivate):hover {
            background-color: inherit;
        }
    }

    .ModalHeaderPricing {
        padding: 24px 24px 17px 24px;
    }

    &.ListProductLineConfirmPopoverColor {
        .GroupSelectColor {
            width: 335px;
            background: #ffffff;
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
            border-radius: 4px;
            padding: 16px;
            .SelectColor {
                .Title {
                    font-weight: bold;
                    font-size: 14px;
                    margin-bottom: 12px;
                    width: 100%;
                    color: #2f3230;
                }
                ul li {
                    float: left;
                    padding-right: 24px;
                    font-size: 14px;
                    line-height: 20px;
                    color: #a9afab;
                }
                ul {
                    .Activate {
                        color: #2f3230;
                    }
                }
                ul li:hover {
                    cursor: pointer;
                }
                .CheckBoxColor {
                    li {
                        float: left;
                        padding: 0;
                        .InputColor {
                            padding: 0;
                            height: initial;
                            width: initial;
                            margin-bottom: 0;
                            display: none;
                            cursor: pointer;
                        }
                        .CheckMark {
                            -webkit-appearance: none;
                            border: 1px solid #d3dcd7;
                            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
                            padding: 10px;
                            display: inline-block;
                            position: relative;
                            vertical-align: middle;
                            cursor: pointer;
                            margin-right: 8px;
                            border-radius: 50%;
                            width: 28px;
                            height: 28px;
                            background-color: #15cd72;
                        }

                        .InputColor:checked + .CheckMark:after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 4px;
                            left: 11px;
                            width: 6px;
                            height: 14px;
                            border: solid white;
                            border-width: 0 2px 2px 0;
                            transform: rotate(45deg);
                        }

                        .InputColor:checked + .CheckMark::before {
                            content: '';
                            background-color: rgba(0, 0, 0, 0.1);
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                        }
                    }
                }
            }
            .IconRemove {
                position: absolute;
                top: 2px;
                right: 10px;
                cursor: pointer;
                transition: all 0.3s;
                color: #6e7370;

                &:hover {
                    color: #dc3545;
                }
            }

            .ButtonDone {
                background-color: #15cd72;
                border-radius: 4px;
                border: none;
                height: 40px;
                line-height: 40px;
                color: #fff;
                padding: 0 30px;
                font-weight: 700;
                transition: all 0.3s;

                &:hover {
                    background-color: darken(#15cd72, 10);
                }
            }
        }
        .popover {
            max-width: 340px;
            border: none !important;
            left: 330px !important;
            top: 5px !important;
            .arrow::after {
                display: none;
            }
            .arrow::before {
                display: none;
            }
        }
    }
}
.newVersion {
    .ListProductLineConfirm {
        .HeaderListProductLine {
            padding: 22px 32px 0 32px;
        }
    }
    .PrintFileRequirement {
        padding: 0 12px 12px 12px;
        ul {
            padding-left: 20px;
            li {
                font-size: 14px;
                list-style: disc;
                margin-bottom: 4px;
                color: #65676B;
            }
        }
    }
    @media (max-width: 1600px) {
        .PrintFileRequirement {
            padding: 0 18px 12px 18px;
            ul {
                padding-left: 15px;
                li {
                    font-size: 12px;
                }
            }
        }
    }
    @media (max-width: 1367px) {
        .PrintFileRequirement {
            padding: 0 12px 12px 12px;
            ul {
                padding-left: 15px;
                li {
                    font-size: 12px;
                }
            }
        }
    }
}
.AdvancePricingByVariants {
    border-radius: 4px;
    border-right: none;
    background: #ffffff;
    .Table {
        font-size: 14px;
        thead th {
            vertical-align: unset;
            color: rgba(47, 50, 48, 1);
            font-size: 14px;
        }
        select,
        input {
            background-color: #ffffff;
            border: 1px solid #e9eeec;
            box-sizing: border-box;
            border-radius: 4px;
        }
    }

    .DropdownMenuFilter {
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        padding: 16px;
        font-weight: normal;
        font-size: 14px;
        min-width: 120px;
    }
    .DropdownMenuFilter ul {
        li {
            .custom-control-label::before,
            .custom-control-label::after {
                top: 0.15rem;
            }
            padding: 5px 0;
        }
        .SelectAll {
            border-bottom: 1px solid #dee2e6;
        }
        .Manipulation {
            border-top: 1px solid #dee2e6;
            display: flex;
            padding-top: 16px;

            .ItemButton {
                flex-grow: 1;

                &:not(:last-child) {
                    margin-right: 8px;
                }
            }

            button.Submit {
                background-color: #15cd72;
                border: none;
                color: #fff;
                border-radius: 4px;
                height: 31px;
                line-height: 31px;
                font-size: 14px;
                font-weight: 600;
                padding: 0 16px;
                transition: all 0.3s;

                &:not(:disabled):hover {
                    background-color: darken(#15cd72, 10);
                }

                &:disabled {
                    opacity: 0.65;
                }
            }

            button.Cancel {
                border: 1px solid #e9eeec;
                border-radius: 4px;
                background-color: #fff;
                color: #2f3230;
                height: 32px;
                line-height: 32px;
                font-size: 14px;
                font-weight: 700;
                padding: 0 16px;
                transition: all 0.3s;

                &:hover {
                    background-color: darken(#fff, 10);
                    border-color: darken(#fff, 10);
                }
            }
        }
    }

    .DropdownFilter {
        .DropdownToggle:hover {
            cursor: pointer;
        }
    }
    .modal-body {
        max-height: 450px;
        min-height: 400px;
        overflow: auto;
    }
}
@media (min-width: 992px) {
    .AdvancePricingByVariants {
        max-width: 1000px;
    }
}

.ModalListProductLine {
    width: 980px;
    position: fixed;
    margin: 0 auto;
    min-width: 980px;
    overflow-y: auto;
    .modal-content {
        height: 100vh;
        border-radius: 0;
        background: #fff;
        overflow: hidden;
        border: none;
    }
    .HeaderListProduct {
        box-shadow: none;
        padding: 0;
        .RowHeaderListProduct {
            height: auto;
            padding: 24px 15px;
        }
        .TitleHeaderList {
            font-size: 22px;
            margin-bottom: 0;
        }
        .SearchProductMockup {
            min-width: 340px;
        }
    }
    .tab-content-noSideBar {
        height: calc(100vh - 120px);
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background: #d3dcd7;
            border-radius: 6px;
        }
    }
    .ContentListProductMockup {
        grid-template-columns: repeat(4, calc(25% - 18.5px));
        padding-bottom: 40px;
        padding-top: 2px;
    }
}

.AvailableProductVariant {
    .modal-body {
        height: 70vh;
        overflow-y: auto;
        .VariantTable {
            .table-responsive {
                height: fit-content;
                min-height: 500px;
            }
        }
    }
}

