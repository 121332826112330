.DropdownFilterWrapper {
    position: relative;
    label {
        cursor: pointer;
    }

    .btn {
        border-radius: 4px;

        &.isOpen {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .ellipsis {
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
    }

    .checkboxes {
        margin: 0;
        // display: none;
        border: 1px solid #ccc;
        border-top: 0;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        position: absolute;
        width: 100%;
        top: 38px;
        z-index: 10;
        background: #fff;
        .inner-wrap {
            padding: 5px 10px;
            max-height: 250px;
            overflow: auto;
            .ColorItem {
                width: 16px;
                height: 16px;
                border-radius: 50%;
            }
        }
    }
}

