@mixin shadow($color: rgba(0, 0, 0, 0.1), $x: 0, $y: 0, $blur: 10px, $spread: 0) {
  box-shadow: $x $y $blur $spread $color;
}

@mixin flex($direction: row, $justify: flex-start, $align: stretch) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

.shadow-1 {
  @include shadow(rgba(0,0,0,0.12), 0, 4px, 8px,0);
}
.shadow-2 {
    @include shadow(rgba(0,0,0,0.08), 0, 6px, 16px, 0);
}

.flex-center {
  @include flex(row, center, center);
}

.flex-column-center {
  @include flex(column, center, center);
}

.items-center {
  align-items: center;
}
