.ModalUploadArtwork {
    min-width: auto;
    width: 100%;
    max-width: 1280px;

    &.modal-dialog-centered {
        align-items: flex-start;
        margin-top: 100px;
        @media (min-width: 576px) {
            min-height: calc(100% - 200px);
        }
        @media (max-width: 1400px) {
            margin-top: 15px;
        }
    }

    @media (max-width: 1400px) {
        width: calc(100% - 20px);
    }

    .modal-header,
    .modal-footer {
        padding: 0.8rem;
    }

    .modal-header {
        .close {
            opacity: 1;
            line-height: 20px;
            margin-right: -18px;
            margin-top: -12px;
            padding: 5px;

            > span {
                color: #2f3230;
                font-weight: 100;
                opacity: 1;
                font-size: 30px;
                text-shadow: none;
            }

            &:hover {
                opacity: 1;
            }
        }
    }

    .modal-body {
        padding: 0.8rem;
    }

    .Tabs {
        ul {
            margin-bottom: 0;
        }
        .tabUpload {
            color: #a9afab;
            display: inline-block;
            margin-right: 24px;
            cursor: pointer;

            &.active {
                color: #007bff;
                font-weight: 500;
            }

            &:hover {
                opacity: 0.7;
                transition: 0.3s all;
            }

            @media (max-width: 767px) {
                display: block;
                margin-top: 5px;
            }
        }

        .TabContent {
            font-size: 14px;
            padding-top: 0.8rem;

            .UploadFromLocal {
                background-color: #F8F9FA;
                padding: 24px;
                margin-bottom: 24px;
                display: flex;
                align-items: center;
                > label {
                    font-weight: 500;
                    font-size: 16px;
                    margin-right: 8px;
                }
                .YourDevice {
                    label {
                        background-color: #FFFFFF;
                        border-radius: 4px;
                        line-height: 40px;
                        padding: 0 20px;
                        cursor: pointer;
                        border: 1px solid #D8DBDE;
                        svg {
                            path {
                                fill: #212529;
                            }
                        }
                    }
                }
            }

            .PFBoxUploader {
                box-sizing: border-box;
                border-radius: 8px;
                text-align: center;
                border: 2px dashed #dee2e6;

                > label {
                    cursor: pointer;
                    padding: 100px 0;
                }

                img {
                    width: 64px;
                }

                .Text {
                    font-size: 16px;
                    line-height: 20px;
                    color: #6e7370;
                    margin-top: 25px;
                }

                &:hover {
                    .Text {
                        color: #007bff;
                    }
                }

                &.Dragging {
                    background: #f2fff5;
                    border-color: #007bff;
                }
            }
            .UploadArtworkModal {
                margin-top: -24px;
            }
            .UploadByAssets {
                .DesignAsset {
                    .Filter {
                        justify-content: space-between;
                        align-items: center;
                        display: flex;
                        margin-bottom: 24px;
                        padding-bottom: 12px;
                        border-bottom: 1px solid #D8DBDE;
                        > label {
                            font-size: 16px;
                            font-weight: 500;
                        }
                        .InputSearch {
                            width: 360px;
                            margin-right: 24px;
                        }
                        .SortFilter {
                            width: 256px;
                            label {
                                width: 85px;
                            }
                        }
                    }
                }
                // .DesignAsset {
                //     #inputSearch {
                //         width: 90%;
                //     }
                //     .SortBy {
                //         label {
                //             min-width: 60px;
                //         }
                //     }
                // }
                .ListAssets {
                    gap: 24px;
                    display: grid;
                    width: 100%;
                    height: 50vh;
                    grid-template-columns: repeat(4, minmax(0, 1fr));
                    .LoadingScroll {
                        display: flex;
                        grid-column: 1 / -1;
                        align-items: center;
                        justify-content: center;
                    }
                    .AssetItem {
                        position: relative;
                        border-radius: 4px;
                        align-self: start;
                        border: 1px solid #d8dbde;
                        //transition: 0.3s all;
                        > div > svg {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                        .Info {
                            padding: 12px;
                            p {
                                margin-bottom: 4px;
                            }
                        }
                        .Thumbnail {
                            cursor: pointer;
                            aspect-ratio: 1;
                            object-fit: contain;
                            width: 100%;
                            position: relative;
                            z-index: 1;
                            transition: 0.3s all;
                        }
                        .Title {
                            -webkit-line-clamp: 1;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            font-weight: 500;
                            text-overflow: ellipsis;
                        }
                        .ButtonDelete {
                            top: 10px;
                            right: 10px;
                            z-index: 10;
                            cursor: pointer;
                            position: absolute;
                            opacity: 0;
                            pointer-events: none;
                            transition: 0.3s all;
                            width: 32px;
                            height: 32px;
                            border-radius: 50%;
                            border: 1px solid #dc3545;
                            background: #dc3545;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        &:hover {
                            img {
                                transform: scale(1.1);
                            }
                            .ButtonDelete {
                                opacity: 0.8;
                                pointer-events: all;
                            }
                        }
                        &.Active {
                            //box-shadow: 0 0 0 2px #007bff;
                            border: 1px solid #007bff;
                            .Mask {
                                position: absolute;
                                top: -1px;
                                right: -1px;
                                z-index: 5;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 40px;
                                height: 40px;
                                border-color: #007bff #007bff transparent transparent;
                                border-style: solid;
                                border-width: 20px;
                                .Icon {
                                    border-radius: 50%;
                                    color: #fff;
                                    display: inline-flex;
                                    justify-content: center;
                                    align-items: center;
                                    position: absolute;
                                    left: 50%;
                                    top: -11px;
                                }
                            }
                        }
                    }
                    // @media (max-width: 1367px) {
                    //     grid-template-columns: repeat(4, 1fr);
                    // }
                }
                .NoImage {
                    height: 50vh;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .UploadFromLibrary {
                @media (max-width: 767px) {
                    .FilterBox {
                        flex-direction: column;
                        justify-content: flex-start !important;
                        align-items: flex-start !important;
                    }
                }

                .SearchArt {
                    position: relative;
                    @media (max-width: 767px) {
                        max-width: 100%;
                        margin-bottom: 15px;
                    }

                    input {
                        line-height: 38px;
                        width: 478px;
                        padding-top: 0;
                        padding-bottom: 0;
                        padding-left: 40px;
                        height: auto;
                        font-size: 14px;
                        @media (max-width: 991px) and (min-width: 768px) {
                            width: 390px;
                        }
                        @media (max-width: 767px) {
                            max-width: 100%;
                        }
                    }

                    svg {
                        position: absolute;
                        left: 15px;
                        top: 12px;
                    }
                }

                .SortBy {
                    @media (max-width: 767px) {
                        width: 100%;
                        display: block !important;
                    }

                    label {
                        width: 100px;
                        text-align: right;
                        margin-bottom: 0;
                        @media (max-width: 767px) {
                            text-align: left;
                            margin-bottom: 10px;
                        }
                    }

                    select {
                        font-size: 14px;
                        padding-top: 9px;
                        padding-bottom: 8px;
                        height: auto;
                        @media (max-width: 767px) {
                            margin-left: 0 !important;
                        }
                    }

                    .optionsSortBy {
                        position: relative;
                        margin-left: 8px;

                        .optionSelected {
                            width: 260px;
                            background: #ffffff;
                            border: 1px solid #dfe2e6;
                            border-radius: 4px;
                            display: block;
                            line-height: 40px;
                            padding: 0 12px;
                            cursor: pointer;

                            svg {
                                fill: #46484c;
                                position: absolute;
                                right: 17px;
                                top: 50%;
                                transform: translate(0, -50%);
                            }
                        }

                        .listOptions {
                            position: absolute;
                            z-index: 2;
                            width: 100%;
                            background: #ffffff;
                            border: 1px solid #d9d9d9;
                            border-radius: 4px;
                            top: calc(100% + 4px);
                            padding: 18px 31px 10px 36px;

                            .itemOption {
                                margin-bottom: 8px;
                                position: relative;
                                cursor: pointer;

                                svg {
                                    position: absolute;
                                    top: 5px;
                                    left: -22px;
                                }

                                &:hover {
                                    color: #007bff;
                                }
                            }
                        }
                    }
                }

                .libArtworks {
                    overflow-y: scroll;
                    margin-top: 24px;
                    margin-left: -12px;
                    margin-right: -12px;
                    height: 486px;
                    @media (min-width: 992px) and (max-width: 1024px) {
                        height: 350px;
                    }

                    .itemArtwork {
                        width: 20%;
                        padding: 0 12px 24px 12px;
                        @media (max-width: 991px) {
                            width: 33.33%;
                        }
                        @media (max-width: 767px) {
                            width: 50%;
                        }

                        .wrapImage {
                            height: 230px;
                            overflow: hidden;
                            text-align: center;
                            display: flex;
                            align-items: center;
                            border: 1px solid #dee2e6;
                            justify-content: center;
                            position: relative;
                            border-radius: 4px;
                            @media (max-width: 767px) {
                                height: 110px;
                            }

                            img {
                                width: 100%;
                            }

                            .Icon {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                background: #007bff;
                                width: 40px;
                                line-height: 40px;
                                height: 40px;
                                border-radius: 50%;
                                z-index: 1;

                                svg {
                                    fill: #ffffff;
                                }
                            }

                            &:after {
                                background-color: rgba(0, 0, 0, 0.5);
                                content: '';
                                opacity: 0;
                                visibility: hidden;
                                width: 100%;
                                height: 100%;
                                left: 0;
                                top: 0;
                                position: absolute;
                            }
                        }

                        .title {
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 20px;
                            color: #46484c;
                            margin-top: 15px;
                            margin-bottom: 0;
                            overflow-wrap: break-word;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 2;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                        }

                        .infoArtwork {
                            color: #5d5f62;
                            margin-top: 5px;

                            svg {
                                fill: #5d5f62;
                                margin-right: 5px;
                                top: -1px;
                                position: relative;
                            }
                        }

                        &.active {
                            .wrapImage {
                                border-color: #007bff;

                                &:after {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }

                        &:hover {
                            .wrapImage {
                                border-color: #007bff;
                            }
                        }
                    }

                    .Pagination {
                        border-top: none;
                        padding-top: 3px;

                        .page-item {
                            margin-bottom: 0;

                            .page-link {
                                line-height: 32px;
                                padding: 0 12px;
                                font-size: initial;
                            }
                        }
                    }
                }
            }

            .footerPopup {
                border-top: 1px solid #dfe2e6;
                margin: 18px -12px 0;
                padding: 18px 18px 0;

                button {
                    padding: 0 24px;
                    line-height: 38px;
                    margin-left: 8px;
                    min-width: 94px;

                    &.btn-default {
                        border-color: #e9eeec;
                    }
                }
            }
        }
    }
}

